import React from "react";
import "./css/Home.css";
import Header from "../components/Header";
import OrgSearch from "../components/OrgSearch";
import { toast } from "react-toastify";

function Home({ logged, setLogged, org, setOrg }) {

  if (!navigator.geolocation) {
    toast.warn("Unable to locate your location.");
  }
  else {
    navigator.permissions
      .query({ name: "geolocation" })
      .then(function (result) {
        if (result.state === "prompt" || result.state === "denied") {
          toast.warn("Could not fetch current location. Please recheck location permissions.");
        }
      });
  }

  return (
    <>
      <Header logged={logged} setLogged={setLogged} />

      <div className="d-flex flex-column align-items-center mt-3">
        <div className="fw-bold mb-3">
          Search and Select an Organization on the Map
        </div>
        <OrgSearch org={org} setOrg={setOrg} />
      </div>
    </>
  );
}

export default Home;
